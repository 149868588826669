<template>
  <div>
    <a
      @click="$router.back()"
      href="#"
      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
    >
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <validation-observer ref="formSiswa">
      <b-form @submit.prevent="submitForm" @reset="onReset">
        <div class="card card-custom card-stretch">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Buat Akun Pegawai
              </span>
            </h3>
            <div class="card-toolbar">
              <button
                type="submit"
                class="btn btn-success font-weight-bolder font-size-sm mr-2"
              >
                Simpan
              </button>
              <span
                @click="$router.back()"
                class="btn btn-secondary font-weight-bolder font-size-sm"
              >
                Batal</span
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <b-form-group id="input-group-1" label="NIK:" label-for="input-1">
              <validation-provider
                #default="{ errors }"
                name="NIK"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentUserForm.body.no_induk"
                  type="text"
                  placeholder="Masukkan NIK"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-1" label="Email:" label-for="input-1">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-input-group class="mt-3">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    id="input-1"
                    v-model="currentUserForm.body.email"
                    type="email"
                    placeholder="Masukkan Email"
                  ></b-form-input>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              prepend="+62"
              label="Nomor WhatsApp:"
              label-for="input-1"
            >
              <validation-provider #default="{ errors }" name="Nomor WhatsApp">
                <b-input-group class="mt-3">
                  <template #prepend>
                    <b-input-group-text
                      ><strong>+62</strong></b-input-group-text
                    >
                  </template>
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    id="input-1"
                    v-model="currentUserForm.body.phone_number"
                    type="number"
                    placeholder="Masukkan Nomor WhatsApp"
                  ></b-form-input>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Nama Pegawai:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Vendor"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentUserForm.body.name"
                  type="text"
                  placeholder="Masukkan Nama Pegawai"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="Role:">
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="currentUserForm.body.role"
                  :options="options"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <div class="separator separator-solid mt-2" v-if="!id"></div>
            <br />
            <div class="d-flex align-items-center" style="gap: 0.5rem">
              <div class="w-100">
                <b-form-group
                  id="input-group-1"
                  label="Password:"
                  label-for="input-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        id="input-1"
                        v-model="currentUserForm.body.password"
                        :type="showPassword ? 'text' : 'password'"
                        placeholder="Masukkan Password"
                      ></b-form-input>
                      <b-input-group-append>
                        <button
                          type="button"
                          @click="showPassword = !showPassword"
                          class="btn btn-icon btn-secondary"
                        >
                          <i
                            class="fa"
                            :class="{
                              'fa fa-eye': showPassword,
                              'fa fa-eye-slash': !showPassword,
                            }"
                          ></i>
                        </button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div>
                <button
                  @click="generatePassword()"
                  type="button"
                  class="btn btn-success"
                >
                  Generate
                </button>
              </div>
            </div>

            <b-form-group label="Hak Akses:">
              <DropdownLevel
                :initValue="currentUserForm.body.access_rights"
                @selected="getHakAkses"
              />
            </b-form-group>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      size="xl"
      :no-close-on-backdrop="true"
      v-model="modalAkses"
      centered
      hide-footer
      scrollable
      title="Menu Akses"
    >
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PilihHakAkses from "../hak-akses/PilihHakAkses.vue";
import {
  ROLE_STUDENT,
  UPDATE_STUDENT,
} from "@/core/services/store/user.module";
import { REGISTER } from "@/core/services/store/auth.module";

import Swal from "sweetalert2";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import DropdownLevel from "../summary/components/DropdownLevel.vue";

import mixin from "@/core/services/Helpers";

export default {
  name: "SiswaForm",
  mixins: [mixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    DropdownLevel,
  },
  data() {
    return {
      currentUserForm: {
        body: {
          role: ROLE_STUDENT,
          access_rights: null,
        },
      },
      options: [
        { value: ROLE_STUDENT, text: "Pegawai" },
        { value: "staff", text: "Staff" },
      ],
      isLoading: false,
      editorOption: {
        // Some Quill options...
      },
      modalAkses: false,
      showPassword: false,
    };
  },
  methods: {
    submitForm() {
      if (this.currentUserForm.body.phone_number)
        this.currentUserForm.body.phone_number =
          "62" + this.currentUserForm.body.phone_number;
      if (this.currentUserForm.body.access_rights.length == 0) {
        Swal.fire({
          title: "Pemberitahuan",
          text: "Silahkan isi level akses",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }

      this.$refs.formSiswa.validate().then((success) => {
        this.isLoading = true;
        if (success) {
          if (this.id) {
            this.currentUserForm._id = this.currentUserSelected._id;
          }

          if (this.vendorId) {
            this.currentUserForm.body.vendor_id = this.vendorId;
          }

          const _data = JSON.parse(JSON.stringify(this.currentUserForm));
          this.$store
            .dispatch(this.id ? UPDATE_STUDENT : REGISTER, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${this.id ? "mengubah" : "membuat"} Pegawai`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              this.$router.push(`/employee`);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal ${this.studentId ? "mengubah" : "membuat"} Siswa`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    onReset() {},
    getHakAkses(item) {
      this.currentUserForm.body.access_rights = item;
    },
    generatePassword() {
      const length = 8; // Change the length of the random string as needed
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var result = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      this.$set(this.currentUserForm.body, "password", result);
    },
  },
  mounted() {
    if (this.id) {
      const currentUser = this.currentUserSelected;
      if (currentUser.role === undefined) {
        this.$router.back();
      }

      this.currentUserForm = {
        body: {
          role: currentUser.role,
          name: currentUser.student.full_name,
          no_induk: currentUser.student.no_induk,
          email: currentUser.email,
          phone_number: currentUser.phone_number
            ? currentUser.phone_number.substring(2)
            : "",
          password: currentUser.password,
          access_rights: currentUser.access_rights,
          address: null,
        },
      };
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUserSelected"]),
    id() {
      return this.$route.params.id;
    },
    vendorId() {
      const user = this.$store.getters.authUser;
      return this.getVendorId(user);
    },
  },
  watch: {
    "currentUserForm.body.no_induk": function (newVal) {
      this.currentUserForm.body.username = newVal;
    },
  },
};
</script>
